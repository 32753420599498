
import { Vue, Component, Watch } from 'vue-property-decorator';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import homeFooter from '@/Layout/components/homeFooter.vue';
import { storageKey } from '@/enums/localStorage';
import axios from 'axios';
import { getPostList } from '@/api/post';
import { getTfseCommunityList } from '@/api/tfse';
import { getAlarmCount, getAlarmList } from '@/api/pushAlarm';
import { getNewAlarmCount } from '@/api/newAlarm';
import { getBestTagList } from '@/api/postTag';
import { RoutingModule } from '@/store/modules/routing';
import 'popper.js';
import { UserModule } from '@/store/modules/user';

@Component({
  components: {
    VueSlickCarousel,
    homeFooter,
  },
})

export default class extends Vue {
  async mounted() {
    RoutingModule.setPoint('Home');
    await this.getMainPostList();
    await this.getTfseCommunityList();
    await this.getAlarmList();
    this.getNewAlarmCount();
    this.getBestTagList();
    // console.log(
      // JSON.parse((window as any).localStorage.getItem(storageKey.pickUpPlace)),
    // );
    // console.log((window as any).localStorage.getItem('jwttoken'));
  }

  private formVisible = false;

  private newAlarmCount = 0;

  private pickUpShop = JSON.parse(
    (window as any).localStorage.getItem(storageKey.pickUpPlace),
  );

  private station = JSON.parse(
    (window as any).localStorage.getItem(storageKey.stationPlace),
  );

  private commonApiUrl = process.env.VUE_APP_COMMON_API;

  private settings = {
    dots: true,
    centerMode: true,
    centerPadding: '20px',
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
  };

  private set2 = {
    dots: true,
    focusOnSelect: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    touchThreshold: 5,
  };

  private set3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  private consultListQuery = {
    boardUid: 'd485f6c8-ea3b-439e-9308-80a5eaf3ffe0',
    page: 1,
    size: 4,
  };

  private feedListQuery = {
    boardUid: 'bb4db5fe-8083-4d87-96f7-7c1ae66b7e76',
    page: 1,
    size: 8,
  };

  private tfseListQuery = {
    communitySearch: true,
    page: 0,
    size: 8,
  };

  private showPopover = false;

  private consultList: any = [];

  private feedList: any = [];

  private tfseList: any = [];

  private searchValue = '';

  private bestTagList = [];

  private getNewAlarmCount() {
    getNewAlarmCount().then((res) => {
      this.newAlarmCount = res.data;
    });
  }

  private handleSearch() {
    if (this.searchValue) {
      this.$router.push({
        name: 'Search',
        query: { searchValue: this.searchValue },
      });
    } else {
      this.$message.warning('검색어를 입력하세요.');
    }
  }

  private async getMainPostList() {
    await getPostList(this.consultListQuery).then((res) => {
      this.consultList = res.data.content;
    });
    await getPostList(this.feedListQuery).then((res) => {
      this.feedList = res.data.content;
    });
  }

  private async getTfseCommunityList() {
    await getTfseCommunityList(this.tfseListQuery).then((res) => {
      this.tfseList = res.data.content;
    });
  }

  private getBestTagList() {
    getBestTagList().then((res) => {
      this.bestTagList = res.data;
    });
  }

  private alarmCount = 0;

  private alarmList: any[] = [];

  private alarmList2: any[][] = [];

  private totalElements = 0;

  private totalPages = 0;

  private listQuery = {
    page: 0,
    size: 5,
  };

  private handleOrderTypeForm() {
    this.formVisible = !this.formVisible;
  }

  private handlePickupForm() {
    this.handleOrderTypeForm();
    // this.$confirm('현재 선택된 픽업매장을 변경하시겠습니까?', '매장 선택', {
    //       confirmButtonText: '네',
    //       cancelButtonText: '아니요',
    // }).then((result) => {
    //   this.$router.push({ name: 'Map', query: { type: 'pickup' } });
    // });
  }

  private handleOrderPick(type: string) {
      if (type === 'station') {
        if (window.localStorage.getItem(storageKey.stationPlace) == null) {
          this.$router.push({ name: 'Map2', query: { type: 'station' } });
        } else {
          this.$confirm('현재 선택된 배송지로 주문하시겠습니까?', '배송지 선택', {
            confirmButtonText: '네',
            cancelButtonText: '아니요',
          }).then((result) => {
            this.$router.push({ name: 'CustomProduct' });
          }).catch(() => {
            this.$router.push({ name: 'Map2', query: { type: 'station' } });
          });
        }
      } else if (type === 'pickup') {
        if (window.localStorage.getItem(storageKey.pickUpPlace) == null) {
          this.$router.push({ name: 'Map', query: { type: 'pickup' } });
        } else {
          this.$confirm('현재 선택된 픽업매장으로 주문하시겠습니까?', '매장 선택', {
            confirmButtonText: '네',
            cancelButtonText: '아니요',
          }).then((result) => {
            this.$router.push({ name: 'Order' });
          }).catch(() => {
            this.$router.push({ name: 'Map', query: { type: 'pickup' } });
          });
        }
          // if (window.localStorage.getItem(storageKey.pickUpPlace) == null) {
          // this.$router.push({ name: 'Map', query: { type: 'pickup' } });
        // } else {
          // this.$router.push({ name: 'Order' });
        // }
      }
  }

  private handleGoDiary() {
    // if (UserModule.isLogin) {
      this.$router.push({ name: 'Diary' });
    // } else {
      // this.$message.info('로그인이 필요한 서비스 입니다.');
      // this.$router.push({ name: 'Login' });
    // }
  }

  private getAlarmCount() {
    getAlarmCount().then((res) => {
      this.alarmCount = res.data;
    });
  }

  private async getAlarmList() {
    await getAlarmList(this.listQuery).then((res) => {
      this.alarmList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.totalPages = res.data.totalPages;
      const itemMap: Map<string, any[]> = new Map();
      if (this.alarmList !== undefined) {
        this.alarmList.forEach((item: any) => {
          const date: string = item.createDate.substring(0, 10); // 날짜 부분만 추출
        if (!itemMap.has(date)) {
          itemMap.set(date, []);
        }
        const itemList: any[] | undefined = itemMap.get(date);
        if (itemList) {
          itemList.push(item);
        }
      });
      this.alarmList2 = Array.from(itemMap.values());
    } else {
      this.alarmList = [];
      this.alarmList2 = [];
    }
  });
    // console.log(itemMap);
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getAlarmList();
  }

  private handleClickMap() {
    // if (UserModule.isLogin) {
      this.$router.push({ name: 'Map' });
    // } else {
      // this.$message.info('로그인이 필요한 서비스 입니다.');
      // this.$router.push({ name: 'Login' });
    // }
  }
}
